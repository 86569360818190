import { type SelectOptionProps } from '../components/inputs/select-box/select-box.component';

/**
 * Max number of search results. Request offset + pageSize must be inside this limit.
 */
export const SEARCH_RESULT_LIMIT = 10000;

/**
 * Page size options for pagination.
 */
export const PAGESIZE_OPTIONS = [24, 36, 48];

/**
 * Default page size for pagination.
 */
export const DEFAULT_PAGESIZE = PAGESIZE_OPTIONS[0];

/**
 * Default page size for category pages.
 */
export const DEFAULT_CATEGORY_PAGESIZE = PAGESIZE_OPTIONS[2];

/**
 * Default sort option for products.
 */
export const DEFAULT_PRODUCT_SORT = 'BEST_MATCH';

/**
 * Separator facets in URL.
 */
export const FACET_SEPARATOR = '~';

/**
 * Separator between facet ID and value in URL.
 */
export const FACET_ID_SEPARATOR = ':';

/**
 * Separator between low range and high range values in the Range Facet
 */
export const RANGE_FACET_VALUE_SEPARATOR = '->';

/**
 * Name of quickship facet group as returned by resolver.
 */
export const QUICKSHIP_FACET_GROUP_NAME = 'Quick Ship';

/**
 * Display name to use for quickship facet group.
 */
export const QUICKSHIP_FACET_GROUP_DISPLAY_NAME = 'Fast and Free Shipping';

/**
 * Value of quickship facet;
 */
export const QUICKSHIP_FACET_VALUE = '1-2day';

/**
 * Display value to use for quickship facet.
 */
export const QUICKSHIP_FACET_DISPLAY_VALUE = 'FREE 1-2 Day Shipping';

/**
 * Legacy sortBy values mapped to current format
 * Used to support and transform legacy URL structure
 */
export const LEGACY_SORT_MAP = {
	SCORE: 'BEST_MATCH',
	PRICE_HIGH: 'PRICE_HI_LOW',
	PRICE_LOW: 'PRICE_LOW_HI',
	PRODUCT_ID: 'MODEL_NUMBER',
	RATING: 'RATING'
};

/**
 * SOLR facetId structure
 * Used to support and transform legacy URLs to current structure
 */
export const LEGACY_FACET_ID = /^[Ff]([0-9]+)$/;

/**
 * Legacy search param keys
 * Used to support and transform legacy URLs to current structure
 */
export const LEGACY_PARAMS = ['r', 's', 'p'];

/**
 * Regex to capture facet id, optional range bound, and value from a single facet string in the URL.
 */
export const REGEX_FACET_PARTS = /([^:]+)(?::(low|high))?:(.+)/;

/**
 * Regex to test if a facet ID ends in _b or _bs, indicating it is a boolean facet.
 */
export const REGEX_FACET_ID_BOOLEAN = /_bs?$/;

export const SORT_OPT_PREFIX = 'Sort By: ';

export const SEARCH_SORT_OPTIONS: SelectOptionProps[] = [
	{ name: `${SORT_OPT_PREFIX}Best Match`, value: 'BEST_MATCH' },
	{ name: `${SORT_OPT_PREFIX}Price (High to Low)`, value: 'PRICE_HI_LOW' },
	{ name: `${SORT_OPT_PREFIX}Price (Low to High)`, value: 'PRICE_LOW_HI' },
	{ name: `${SORT_OPT_PREFIX}Model Number`, value: 'MODEL_NUMBER' },
	{ name: `${SORT_OPT_PREFIX}Highest Rated`, value: 'RATING' },
	{ name: `${SORT_OPT_PREFIX}Most Popular`, value: 'POPULAR' }
];

export const SEARCH_SORT_OPTIONS_MOBILE: SelectOptionProps[] = [
	{ name: 'Best Match', value: 'BEST_MATCH' },
	{ name: 'Price (High to Low)', value: 'PRICE_HI_LOW' },
	{ name: 'Price (Low to High)', value: 'PRICE_LOW_HI' },
	{ name: 'Model Number', value: 'MODEL_NUMBER' },
	{ name: 'Highest Rated', value: 'RATING' },
	{ name: 'Most Popular', value: 'POPULAR' }
];

// Category drops doesn't use the POPULAR sort, and the BEST_MATCH option is named Best Selling.
export const CATEGORY_SORT_OPTIONS = SEARCH_SORT_OPTIONS.filter((option) => option.value !== 'POPULAR').map((option) =>
	option.value === 'BEST_MATCH' ? { ...option, name: `${SORT_OPT_PREFIX}Best Selling` } : option
);

export const STOCK_STATUS_OPTIONS: SelectOptionProps[] = [
	{ name: 'Nonstock: Hide', value: 'false' },
	{ name: 'Nonstock: Show', value: 'true' }
];

export const SORT_BY_MAP = {
	BEST_MATCH: 'Best Match',
	PRICE_HI_LOW: 'Price (High to Low)',
	PRICE_LOW_HI: 'Price (Low to High)',
	MODEL_NUMBER: 'Model Number',
	RATING: 'Highest Rated',
	POPULAR: 'Most Popular'
};

export const FACET_NUMBER_MIN_DIGITS = 0;
export const FACET_NUMBER_MAX_DIGITS = 5;
export const FACET_NUMBER_STRIP_INTEGER_ZEROS = true;

export const FACET_MONEY_MIN_DIGITS = 2;
export const FACET_MONEY_MAX_DIGITS = 2;
export const FACET_MONEY_STRIP_INTEGER_ZEROS = true;

export const FACET_GROUP_FILTER_SHOW_SIZE = 15;

export const FACET_FILTER_HIDE_GROUP_NAME = new Set(
	[
		QUICKSHIP_FACET_GROUP_NAME,
		'Brand',
		'Color',
		'Customer Rating',
		'DC Inventory',
		'Features',
		'Finish',
		'Finish Application',
		'More Ways to Shop',
		'Price',
		'Price Range',
		'Product Category',
		'Product Type',
		'Theme'
	].map((name) => name.toLowerCase())
);
