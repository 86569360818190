import { useCallback, useEffect } from 'react';
import { LARGE_MEDIA_QUERY, MEDIUM_MEDIA_QUERY, NOT_SMALL_MEDIA_QUERY } from '../../constants/media-queries';

/**
 * Listen for change events on a given media query and pass them to the supplied callback.
 */
export function useMediaQueryChangeListener(mediaQuery: string, onChange: (mediaQueryEvent: MediaQueryListEvent) => void) {
	useEffect(() => {
		const mediaQueryList = window.matchMedia(mediaQuery);
		mediaQueryList.addEventListener('change', onChange);
		return () => mediaQueryList.removeEventListener('change', onChange);
	}, [mediaQuery, onChange]);
}

/**
 * Create a media query match listener for a given mediaQuery, leveraging useMediaQueryChangeListener.
 * The resulting function accepts an onChange event that will be called on mediaQuery
 * match status change, with a boolean indicating if the mediaQuery was matched or not.
 */
const createMediaQueryMatchListener = (mediaQuery: string) => (onChange: (match: boolean) => void) =>
	useMediaQueryChangeListener(
		mediaQuery,
		useCallback((mediaQueryEvent: MediaQueryListEvent) => onChange(mediaQueryEvent.matches), [onChange])
	);

/**
 * Convenience wrapper around useMediaQueryListener for NOT_SMALL_MEDIA_QUERY.
 */
export const useNotSmallBreakpointListener = createMediaQueryMatchListener(NOT_SMALL_MEDIA_QUERY);

/**
 * Convenience wrapper around useMediaQueryListener for MEDIUM_MEDIA_QUERY.
 */
export const useMediumBreakpointListener = createMediaQueryMatchListener(MEDIUM_MEDIA_QUERY);

/**
 * Convenience wrapper around useMediaQueryListener for LARGE_MEDIA_QUERY.
 */
export const useLargeBreakpointListener = createMediaQueryMatchListener(LARGE_MEDIA_QUERY);
