import { RANGE_FACET_VALUE_SEPARATOR } from '../../constants/search';
import { type GroupContentItem, type GroupFacetItem } from '../../types/content.types';
import { type FacetFilter, type FacetGroup, type SelectedFacetGroup } from '../../types/search.types';

export const getBrandLogoImageRelativeUrl = (brandName: string): string => {
	let brandImageUrl = '';
	// - lowercase and replace empty spaces
	// - replace "&" with "and"
	brandName = (brandName || '').toLowerCase().replace(/\s/g, '').replace(/&/g, 'and');
	if (brandName) {
		brandImageUrl = `mediabase/manufacturers/${brandName}/logo/${brandName}`;
	}
	return brandImageUrl;
};

/**
 * Find group item facets that have no match in the facet groups from search results.
 */
export const getNonCompatibleFacets = (
	item: GroupContentItem,
	facetGroups?: FacetGroup[],
	selectedFacetGroups?: SelectedFacetGroup[]
): GroupFacetItem[] => {
	if (!facetGroups || !item.facets) {
		return item.facets || [];
	}

	// Build a map of facetId to source facet IDs from selected facets. This will help us match up available facets
	// in the search response to facets in the group content item when we're using Solr to Fusion translation.
	const selectedFacetSourceIdMap =
		selectedFacetGroups
			?.flatMap((facetGroup) => facetGroup.facets)
			.filter((f) => f.sourceFacetId)
			.reduce((result, f) => {
				if (!result[f.facetId]) {
					result[f.facetId] = new Set();
				}
				result[f.facetId].add(f.sourceFacetId);
				return result;
			}, {}) ?? [];

	return item.facets.filter(
		(facet) =>
			!facetGroups.some((availableFacetGroup) => {
				// Quick check if we should inspect all the facets in this group for a match. If group name doesn't match,
				// check the first facet ID against selected facet source IDs. This helps multi-select work when we have
				// Fusion facets in search results, but group items are still using Solr facets that get translated.
				const groupMatch =
					availableFacetGroup.name === facet.groupName ||
					selectedFacetSourceIdMap[availableFacetGroup.facets[0]?.facetId]?.has(facet.id);

				// Check each facet in the available group to see if it matches the group content facet. We will again check
				// that either ID or translated source ID matches.
				return (
					groupMatch &&
					availableFacetGroup.facets.some(
						(availableFacet) =>
							availableFacet.value === facet.value &&
							(availableFacet.facetId === facet.id || selectedFacetSourceIdMap[availableFacet.facetId]?.has(facet.id))
					)
				);
			})
	);
};

function constructFacetMatchesSelectedFacetGroup(groupItemFacet: GroupFacetItem, selectedFacetGroup: SelectedFacetGroup) {
	const constructRange = groupItemFacet.range;
	if (constructRange) {
		return (
			selectedFacetGroup.range &&
			selectedFacetGroup.facets[0].facetId === groupItemFacet.id &&
			selectedFacetGroup.range.min === constructRange.min &&
			selectedFacetGroup.range.max === constructRange.max
		);
	}
	return selectedFacetGroup.facets.some(
		(selectedFacet) =>
			(selectedFacet.facetId === groupItemFacet.id || selectedFacet.sourceFacetId === groupItemFacet.id) &&
			selectedFacet.value === groupItemFacet.value
	);
}

export const findIsSelected = (groupItemFacets?: GroupFacetItem[], selectedFacetGroups?: SelectedFacetGroup[]): boolean => {
	if (!groupItemFacets?.length || !selectedFacetGroups?.length) {
		return false;
	}
	return groupItemFacets.every((groupItemFacet) =>
		selectedFacetGroups.some((facetGroup) => constructFacetMatchesSelectedFacetGroup(groupItemFacet, facetGroup))
	);
};

export const convertConstructFacetsToFacetFilters = (constructFacets: GroupFacetItem[]): FacetFilter[] => {
	return constructFacets.map((f) => ({
		id: f.id,
		value: f.range ? `${f.range.min}${RANGE_FACET_VALUE_SEPARATOR}${f.range.max}` : f.value
	}));
};

const TAB_COMMON = 'br2-ns ba br--top-ns pa2 b--theme-grey-light content-center';
export const getTabStyles = (tabIndex: number, totalTabs: number) => {
	let tabStyles = TAB_COMMON;

	if (tabIndex < totalTabs - 1) {
		tabStyles += ' mr1-ns';
	}

	return tabStyles;
};
