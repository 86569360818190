import { LARGE_MEDIA_QUERY, MEDIUM_MEDIA_QUERY, NOT_SMALL_MEDIA_QUERY } from '../../constants/media-queries';

/*
 * Helpers for use in client-side code such as click handlers when we can't use the client-only-breakpoints
 * hook due to the component itself being rendered during SSR.
 */

export const windowIsNotSmall = () => window.matchMedia(NOT_SMALL_MEDIA_QUERY).matches;

export const windowIsSmall = () => !windowIsNotSmall();

export const windowIsMedium = () => window.matchMedia(MEDIUM_MEDIA_QUERY).matches;

export const windowIsLarge = () => window.matchMedia(LARGE_MEDIA_QUERY).matches;
